











































































































import Vue from "vue";
import { mapState } from "vuex";
import { request, url } from "@/api";

export default Vue.extend({
  name: "AMHatsuNumber",
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      images: "images",
      oss: "oss",
      url: "url"
    })
  },
  /* eslint-disable */
  data() {
    return {
      year: new Date().getFullYear(),
      code: "",
      order_no: "",
      number: 0,
      email: "",
      name: "",
      orderVisible: true,
      infoVisible: false,
      count: 0,
      order_info: {
        code: "",
        create_time: "",
        id: 0,
        number: 0,
        order_no: "",
        section_id: 0,
        update_time: ""
      },
      section_info: {
        end: 0,
        id: 0,
        start: 0
      },
      select_info: {
        count: 0,
        numbers: [] as number[]
      },
      user_index: 0
    };
  },
  methods: {
    stringNumber(number: number) {
      if (number < 10) {
        return "000" + number;
      } else if (number < 100) {
        return "00" + number;
      } else if (number < 1000) {
        return "0" + number;
      }
      return number;
    },
    onOrderSubmit() {
      if (!this.order_no) {
        this.$message.info(
          "" + this.$t("inputError", [this.$t("AMHatsuNumber.orderNo")])
        );
        return;
      }
      request({
        url: url.AMHatsuNumber,
        params: {
          code: this.code,
          order_no: this.order_no
        }
      }).then((res: any) => {
        this.orderVisible = false;
        this.count = res.count;
        this.order_info = res.order_info;
        this.section_info = res.section_info;
        this.select_info = res.select_info;
        this.user_index = res.user_index;
      });
    },
    onSelect(number: number) {
      if (this.order_info.number) {
        this.$message.info(
          "" +
            this.$t("AMHatsuNumber.selected") +
            this.stringNumber(this.order_info.number)
        );
      } else if (this.select_info.count + 1 !== this.user_index) {
        this.$message.info("" + this.$t("AMHatsuNumber.notArrived"));
      } else if (this.select_info.numbers.includes(number)) {
        this.$message.info("" + this.$t("AMHatsuNumber.cannot"));
      } else {
        this.number = number;
        this.infoVisible = true;
      }
    },
    onInfoSubmit() {
      const emailRe = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      if (!emailRe.test(this.email)) {
        this.$message.info(
          "" + this.$t("inputError", [this.$t("AMHatsuNumber.email")])
        );
        return false;
      } else if (!this.name) {
        this.$message.info(
          "" + this.$t("inputError", [this.$t("AMHatsuNumber.name")])
        );
        return false;
      }

      request({
        method: "post",
        url: url.AMHatsuNumber,
        data: {
          code: this.code,
          order_no: this.order_no,
          number: this.number,
          email: this.email,
          name: this.name
        }
      }).then(() => {
        this.$message.info(
          "" + this.$t("AMHatsuNumber.success") + this.stringNumber(this.number)
        );
        this.onOrderSubmit();
        this.infoVisible = false;
      });
    },
    setLanguage(language: string) {
      const oldLanguage = this.$route.params.language;
      if (oldLanguage) {
        this.$router.replace({
          path: this.$route.path.replace(oldLanguage, language),
          query: this.$route.query
        });
      } else {
        this.$router.replace({
          path: "/" + language + this.$route.path,
          query: this.$route.query
        });
      }
    }
  },
  mounted() {
    const code = this.$route.query.code;
    if (code) {
      this.code = String(code);
    } else {
      this.$router.replace(this.$paths.AMHatsu);
    }
  }
});
